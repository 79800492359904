import React from "react";
import {
  Box,
  Heading,
  Text,
  Link,
  Image,
  Button,
  Stack,
  HStack,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { RWebShare } from "react-web-share";
import { GoShare } from "react-icons/go";
const FeedPost = ({ post }) => {
  const toast = useToast();
  const {
    contentMedia,
    postTitle,
    postText,
    postButtonLink,
    postButtonText,
    dateTime,
  } = post.fields;

  const hostName =
    process.env.NODE_ENV === "production"
      ? "http://city.recolorred.online"
      : "http://stg.recolorred.online";
  return (
    <Box
      id={post.sys.id}
      fontFamily="Manrope"
      border="1px"
      borderColor="grey"
      bg="white"
      fontSize="14px"
      maxW={{
        base: "360px",
        sm: "400px",
        lg: "660px",
      }}
      mx={{ base: "1.5rem", sm: "0" }}
    >
      <Stack>
        <Box>
          {contentMedia && <Image src={contentMedia.fields.file.url} />}
        </Box>
        <Box mx="1.5" p="2" border="1px" borderColor="grey">
          <Heading fontFamily="Manrope" as="h1" color="black" size="12px">
            {postTitle}
          </Heading>
          <Text fontSize="14px" color="black">
            {postText}
          </Text>
        </Box>

        {postButtonLink || postButtonText ? ( // Überprüfen, ob entweder der Link oder der Text vorhanden ist
          <Box border="1px" mx="1.5" p="2" borderColor="grey">
            {postButtonLink ? (
              <Button
                _hover={{ opacity: "80%" }}
                color={postButtonText === "Shop Now" ? "white" : "black"}
                borderRadius="0"
                backgroundColor={
                  postButtonText === "Shop Now" ? "black" : "white"
                } // Ändere das Hintergrunddesign basierend auf dem Text
                fontSize={{ base: "11px", sm: "14px" }}
                textTransform="uppercase"
                textDecor="none !important"
                as={Link}
                href={postButtonLink}
                isExternal
              >
                {postButtonText}
              </Button>
            ) : (
              <Button
                _hover={{ opacity: "80%" }}
                borderRadius="0"
                color={postButtonText === "Shop Now" ? "white" : "black"}
                backgroundColor={
                  postButtonText === "Shop Now" ? "black" : "white"
                } // Ändere das Hintergrunddesign basierend auf dem Text
                fontSize={{ base: "11px", sm: "14px" }}
                textTransform="uppercase"
                textDecor="none !important"
              >
                {postButtonText || "Read More"}
              </Button>
            )}
          </Box>
        ) : null}

        <HStack justify="space-between" px="2">
          <Text pb="2" mx="2">
            {dateTime}
          </Text>

          <RWebShare
            data={{
              text: postText,
              url: `${hostName}/post/${post.sys.id}`, // replace with your link
              title: postTitle,
            }}
            sites={["whatsapp", "copy"]}
            onClick={() => {
              toast({
                title: "Post shared successfully",
                status: "success",
                duration: 2000,
              });
            }}
          >
            <Icon cursor="pointer" pb="2" boxSize={8} as={GoShare} />
          </RWebShare>
        </HStack>
      </Stack>
    </Box>
  );
};

export default FeedPost;
