import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Heading,
  Text,
  HStack,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
const PopupRedirectModal = () => {
  const [cookies, setCookie] = useCookies();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handle18Plus = () => {
    setCookie("age_restricted", false, { path: "/" });
    onClose();
  };

  const redirectToMainWebsite = () => {
    window.location.href = "https://recolorred.online";
  };

  const [isSmallMobile] = useMediaQuery("(max-width: 450px)");

  useEffect(
    () => {
      const isRestricted = cookies.age_restricted;
      if (isRestricted === false) {
        onClose();
      } else {
        if (isOpen === false) {
          onOpen();
        }
      }
    },
    // eslint-disable-next-line
    [cookies, isOpen]
  );

  return (
    <>
      <Modal
        size={isSmallMobile ? "s" : "2xl"}
        isOpen={isOpen}
        onClose={() => {}}
      >
        <ModalOverlay backdropFilter="auto" backdropBlur="40px" />
        <ModalContent m="1.5rem" borderRadius="0">
          <ModalBody>
            <Stack py="12">
              {" "}
              <Heading
                px={{ base: "2", md: "8" }}
                fontSize="18"
                textAlign="center"
              >
                ACCESS RESTRICTED <br />
                this website requires users to be 18+ years old
              </Heading>
              <Text
                textAlign="center"
                pt={{ base: "1", md: "4" }}
                fontSize={{ base: "12px", md: "md" }}
              >
                Please confirm that you're full aged <br />
              </Text>
              <HStack mt="8" justify="center">
                <Button
                  _hover="none !important"
                  borderRadius="0"
                  boxShadow="none !important"
                  _focus={{ outline: 0 }}
                  variant="outline"
                  onClick={handle18Plus}
                  fontSize={{ base: "12px", md: "md" }}
                  color="black"
                  bg="red"
                  borderColor="black"
                >
                  I'M OVER 18
                </Button>
                <Button
                  _hover="none !important"
                  borderRadius="0"
                  color="white"
                  bg="black"
                  onClick={() => {
                    redirectToMainWebsite();
                    onClose();
                  }}
                  fontSize={{ base: "12px", md: "md" }}
                >
                  EXIT
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PopupRedirectModal;
