import { client } from "./client";

export const getCityData = async ({ page = 1, limit = 5 }) => {
  const skip = (page - 1) * limit;
  console.log({
    page,
    limit,
    skip,
  });

  console.log({ page, limit, skip });
  try {
    const entries = await client.getEntries({
      content_type: "city",
      order: "-sys.createdAt",
      limit,
      skip,
    });
    return entries.items;
  } catch (error) {
    throw new Error("Error retrieving entries:", error);
  }
};

export const getSingleCityData = async ({ id }) => {
  try {
    const entries = await client.getEntries({
      content_type: "city",
      "sys.id": id,
    });
    return entries.items;
  } catch (error) {
    throw new Error("Error retrieving entries:", error);
  }
};
export const getProrityCityData = async () => {
  try {
    const entries = await client.getEntries({
      content_type: "city",
      order: "-fields.priority",
      limit: 1,
    });

    if (entries.items[0].fields.priority === 1) {
      return entries.items;
    }
    return [];
  } catch (error) {
    throw new Error("Error retrieving entries:", error);
  }
};

export const cityshopBackgroundData = async () => {
  try {
    const entries = await client.getEntries({
      content_type: "cityshopBackground",
    });
    return entries.items;
  } catch (error) {
    throw new Error("Error retrieving entries:", error);
  }
};
