import React, { useContext, useState } from "react";
import parse from "html-react-parser";
import {
  Paypal,
  Mastercard,
  Visa,
  Klarna,
  Applepay,
  Amazon,
} from "react-pay-icons";

import {
  Box,
  Grid,
  Image,
  Text,
  Button,
  Heading,
  Flex,
  Divider,
  HStack,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useMediaQuery,
} from "@chakra-ui/react";

import { ShopContext } from "../../context/shopContext";

import { convertPrice } from "../../utils";
/* GITHUB FOLDER */

const ProductSection = ({ product }) => {
  const { addItemToCheckout, checkout, isLoading } = useContext(ShopContext);
  const [bigImage, setBigImage] = useState("");
  const [selectedVariant, setSelectedVaraint] = useState();

  const selectSize = (size, index) => {
    const variant = product.variants[index];
    setSelectedVaraint(variant);
  };

  const [isMobile] = useMediaQuery("(max-width: 30em)");

  const cartButtonText = () => {
    let buttonText = "SELECT A SIZE";

    if (product?.variants?.length === 1) {
      if (
        checkout?.lineItems?.map((item) => item.title)?.includes(product.title)
      ) {
        buttonText = "ADDED TO BAG";
      } else if (product?.availableForSale) {
        buttonText = "ADD TO BAG";
      } else {
        buttonText = "OUT OF STOCK";
      }
      return buttonText;
    }

    if (selectedVariant) {
      if (
        checkout?.lineItems
          ?.map((item) => item?.variant.id)
          ?.includes(selectedVariant?.id)
      ) {
        buttonText = "ADDED TO BAG";
      } else if (selectedVariant?.available) {
        buttonText = "ADD TO BAG";
      } else {
        buttonText = "OUT OF STOCK";
      }
    }

    return buttonText;
  };
  function isProductInCartOrUnavailable(product, checkout, selectedVariant) {
    if (product?.variants?.length === 1) {
      const productTitlesInCart = checkout?.lineItems?.map(
        (item) => item.title
      );
      const isProductInCart = productTitlesInCart?.includes(product.title);
      const isProductAvailable = product?.availableForSale;

      return isProductInCart || !isProductAvailable;
    } else {
      const variantIdsInCart = checkout?.lineItems?.map(
        (item) => item.variant.id
      );
      const isVariantInCart = variantIdsInCart?.includes(selectedVariant?.id);
      const isVariantAvailable = selectedVariant?.available;

      return isVariantInCart || !isVariantAvailable || !selectedVariant;
    }
  }

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {isMobile ? (
        <Box align="left">
          <Grid
            w={{
              base: "100%",
              md: "50%",
            }}
            templateColumns={["repeat(1, 1fr)"]}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="#F5F5F5"
            >
              <Image src={bigImage ? bigImage : product?.images[0]?.src} />
            </Flex>
            <Flex ml=".5rem" mt=".5rem" mb=".5rem">
              {product?.images?.map((image, index) => {
                if (index < 4) {
                  return (
                    <Image
                      border={
                        bigImage === image.src ? "1px solid black" : "none"
                      }
                      key={index}
                      onClick={() => setBigImage(image.src)}
                      src={image.src}
                      w={50}
                      h={50}
                      mr="8px"
                      background="#F5F5F5"
                    />
                  );
                }
                return null;
              })}
            </Flex>

            <Flex>
              <Heading
                mt=".25rem"
                mb=".25rem"
                ml=".5rem"
                fontSize="11px"
                fontFamily="Helvetica"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                {product?.title}
              </Heading>
            </Flex>

            <Box
              ml=".5rem"
              mt=".5rem"
              mb=".5rem"
              fontSize="11px"
              fontFamily="Helvetica"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              Size:
              <HStack mt="2">
                {product.variants.map((variant, index) => {
                  const selectedBox =
                    selectedVariant?.selectedOptions[0]?.value ===
                    variant.selectedOptions[0].value;

                  const soldOut = variant.available;

                  if (!soldOut) {
                    return (
                      <Center
                        w="35px"
                        border="1px solid black"
                        h="18px"
                        textDecor="line-through"
                        key={variant.selectedOptions[0].value}
                        pos="relative"
                      >
                        {variant.selectedOptions[0].value}

                        <Box pos="absolute" h="3px" bg="black" w="full" />
                      </Center>
                    );
                  }

                  return (
                    <Center
                      key={variant.selectedOptions[0].value}
                      w="35px"
                      border="1px solid black"
                      h="18px"
                      onClick={() => {
                        selectSize(variant.value, index);
                      }}
                      color={selectedBox ? "white" : "black"}
                      bg={selectedBox ? "black" : "white"}
                    >
                      {variant.selectedOptions[0].value}
                    </Center>
                  );
                })}
              </HStack>
            </Box>

            <Text
              ml=".5rem"
              h="1.5rem"
              fontSize="11px"
              fontFamily="Helvetica"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              {" "}
              PRICE: {convertPrice(product?.variants[0]?.price.amount)}€
            </Text>
            <Text
              ml=".5rem"
              mb="1rem"
              color="green"
              fontFamily="Helvetica"
              fontSize="12px"
            >
              {product?.availableForSale && (
                <Text fontSize="12px">Shipped in 1 - 3 days</Text>
              )}
            </Text>
            <Divider borderColor="black" orientation="horizontal" />
            <Box align="center">
              <Button
                isDisabled={isProductInCartOrUnavailable(
                  product,
                  checkout,
                  selectedVariant
                )}
                _focus={{ outline: 0 }}
                onClick={() => {
                  if (product?.variants.length === 1) {
                    addItemToCheckout(product?.variants[0].id, 1);
                  } else {
                    addItemToCheckout(selectedVariant?.id, 1);
                  }
                }}
                _hover={{ opacity: "80%" }}
                my="1rem"
                height="2.8rem"
                // w="20rem"
                backgroundColor="black"
                color="white"
                borderRadius="0px"
                fontSize="1rem"
                w="80%"
                maxW="20rem"
              >
                {cartButtonText()}
              </Button>
            </Box>

            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
              gap={2}
              py=".5rem"
              borderRadius="md"
              fontSize="9px"
              fontFamily="Helvetica"
              m=".5rem"
              textTransform="uppercase"
            >
              <Flex
                bg="#F5F5F5"
                h="2rem"
                align="center"
                justify="center"
                flexDirection="column"
              >
                <Text>Limited handmade Piece</Text>
              </Flex>
              <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                Free Worldwide Shipping
              </Flex>
              <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                30 Days Money Back Guarantee
              </Flex>
              <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                Pay later with Klarna
              </Flex>
            </Grid>

            <Divider borderColor="black" orientation="horizontal" />
            <Flex flexDirection="column">
              <Text
                borderColor="black"
                borderTop="0.5px"
                mt="1rem"
                w="100%"
                pl="0.5rem"
                fontSize="11px"
                fontFamily="Helvetica"
              >
                {parse(product?.descriptionHtml)} {""}
              </Text>
            </Flex>

            <Divider borderColor="black" orientation="horizontal" my="1rem" />

            {
              <Accordion
                mt="1rem"
                allowMultiple
                style={{ margin: 0, padding: 0 }}
                as="span"
                flex="1"
                textAlign="left"
                fontFamily="Helvetica"
                fontSize="11px"
              >
                <AccordionItem border="none">
                  <h2>
                    <AccordionButton
                      _focus={{ outline: "none" }}
                      _hover={{ background: "none" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontFamily="Helvetica"
                        fontSize="12px"
                      >
                        CARE & COMPOSITION
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Cold wash inside out, don’t iron, don’t tumbledry , don’t
                    bleach, avoid fire. <br />
                    100% Cotton fabric. <br />
                    Caution: Fabric might vary depending on the piece, please
                    read the product description.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none">
                  <h2>
                    <AccordionButton
                      _focus={{ outline: "none" }}
                      _hover={{ background: "none" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontFamily="Helvetica"
                        fontSize="12px"
                      >
                        SHIPPING & PAYMENT
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <b> Free worldwide shipping on every product.</b> <br />
                    You will receive a shipping number which you can use to
                    track your package on the DHL website. <br />
                    You can return your order anytime within the first 30 days
                    after receiving it. <br />
                    For more information use the contacts tab on the website.
                    <HStack mt="1rem">
                      <Visa style={{ width: 30 }} />
                      <Mastercard style={{ width: 30 }} />
                      <Paypal style={{ width: 30 }} />
                      <Klarna style={{ width: 30 }} />
                      <Applepay style={{ width: 30 }} />
                      <Amazon style={{ width: 30 }} />
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            }
            <br />
          </Grid>
        </Box>
      ) : (
        /* --- HERE COMES THE DESKTOP VERSION: --- */

        <Box align="left">
          <Grid
            w={{
              base: "100%",
              md: "100%",
            }}
            templateColumns={["repeat(2, 1fr)"]}
            gap={4} // Abstand zwischen den Grid-Elementen
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="#F5F5F5"
              gridColumn={1} // Bild in die erste Spalte setzen
            >
              <Image src={bigImage ? bigImage : product?.images[0]?.src} />
            </Flex>

            <Flex
              ml=".5rem"
              mt=".5rem"
              mb=".5rem"
              flexDirection="column"
              gridColumn={2} // Titel und Beschreibung in die zweite Spalte setzen
            >
              {/* Product Title */}
              <Heading
                mt=".25rem"
                mb=".25rem"
                ml=".5rem"
                fontSize="11px"
                fontFamily="Helvetica"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                {product?.title}
              </Heading>

              {/* Product Description */}
              <Text
                borderColor="black"
                borderTop="0.5px"
                mt="1rem"
                pl="0.5rem"
                fontSize="11px"
                fontFamily="Helvetica"
              >
                {parse(product?.descriptionHtml)} {""}
              </Text>

              {/* Accordion */}
              <Accordion
                mt="1rem"
                allowMultiple
                style={{ margin: 0, padding: 0 }}
                as="span"
                flex="1"
                textAlign="left"
                fontFamily="Helvetica"
                fontSize="11px"
              >
                <AccordionItem border="none">
                  <h2>
                    <AccordionButton
                      _focus={{ outline: "none" }}
                      _hover={{ background: "none" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontFamily="Helvetica"
                        fontSize="12px"
                      >
                        CARE & COMPOSITION
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Cold wash inside out, don’t iron, don’t tumbledry , don’t
                    bleach, avoid fire. <br />
                    100% Cotton fabric. <br />
                    Caution: Fabric might vary depending on the piece, please
                    read the product description.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none">
                  <h2>
                    <AccordionButton
                      _focus={{ outline: "none" }}
                      _hover={{ background: "none" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontFamily="Helvetica"
                        fontSize="12px"
                      >
                        SHIPPING & PAYMENT
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <b> Free worldwide shipping on every product.</b> <br />
                    You will receive a shipping number which you can use to
                    track your package on the DHL website. <br />
                    You can return your order anytime within the first 30 days
                    after receiving it. <br />
                    For more information use the contacts tab on the website.
                    <HStack mt="1rem">
                      <Visa style={{ width: 30 }} />
                      <Mastercard style={{ width: 30 }} />
                      <Paypal style={{ width: 30 }} />
                      <Klarna style={{ width: 30 }} />
                      <Applepay style={{ width: 30 }} />
                      <Amazon style={{ width: 30 }} />
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={2}
                py=".5rem"
                borderRadius="md"
                fontSize="9px"
                fontFamily="Helvetica"
                m=".5rem"
                textTransform="uppercase"
              >
                <Flex
                  bg="#F5F5F5"
                  h="2rem"
                  align="center"
                  justify="center"
                  flexDirection="column"
                >
                  <Text>Limited handmade Piece</Text>
                </Flex>
                <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                  Free Worldwide Shipping
                </Flex>
                <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                  30 Days Money Back Guarantee
                </Flex>
                <Flex bg="#F5F5F5" h="2rem" align="center" justify="center">
                  Pay later with Klarna
                </Flex>
              </Grid>
              {/* Price */}
              <Text
                ml=".5rem"
                h="1.5rem"
                fontSize="11px"
                fontFamily="Helvetica"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                {" "}
                PRICE: {convertPrice(product?.variants[0]?.price.amount)}€
              </Text>

              {/* Size */}
              <Box
                ml=".5rem"
                mt=".5rem"
                mb=".5rem"
                fontSize="11px"
                fontFamily="Helvetica"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                Size:
                <HStack mt="2">
                  {product.variants.map((variant, index) => {
                    const selectedBox =
                      selectedVariant?.selectedOptions[0]?.value ===
                      variant.selectedOptions[0].value;

                    const soldOut = variant.available;

                    if (!soldOut) {
                      return (
                        <Center
                          w="35px"
                          border="1px solid black"
                          h="18px"
                          textDecor="line-through"
                          key={variant.selectedOptions[0].value}
                          pos="relative"
                        >
                          {variant.selectedOptions[0].value}

                          <Box pos="absolute" h="3px" bg="black" w="full" />
                        </Center>
                      );
                    }

                    return (
                      <Center
                        key={variant.selectedOptions[0].value}
                        w="35px"
                        border="1px solid black"
                        h="18px"
                        onClick={() => {
                          selectSize(variant.value, index);
                        }}
                        color={selectedBox ? "white" : "black"}
                        bg={selectedBox ? "black" : "white"}
                      >
                        {variant.selectedOptions[0].value}
                      </Center>
                    );
                  })}
                </HStack>
              </Box>

              {/* Add to Cart Button */}
              <Box>
                <Button
                  isDisabled={isProductInCartOrUnavailable(
                    product,
                    checkout,
                    selectedVariant
                  )}
                  _focus={{ outline: 0 }}
                  onClick={() => {
                    if (product?.variants.length === 1) {
                      addItemToCheckout(product?.variants[0].id, 1);
                    } else {
                      addItemToCheckout(selectedVariant?.id, 1);
                    }
                  }}
                  _hover={{ opacity: "80%" }}
                  my="1rem"
                  height="2.8rem"
                  backgroundColor="black"
                  color="white"
                  borderRadius="0px"
                  fontSize="1rem"
                  w="80%"
                  maxW="20rem"
                >
                  {cartButtonText()}
                </Button>

                <Text
                  ml=".5rem"
                  mb="1rem"
                  color="green"
                  fontFamily="Helvetica"
                  fontSize="12px"
                >
                  {product?.availableForSale && (
                    <Text fontSize="12px">Shipped in 1 - 3 days</Text>
                  )}
                </Text>
              </Box>
            </Flex>

            {/* Weitere Bilder */}
            <Flex ml=".5rem" mt=".5rem" mb=".5rem">
              {product?.images?.map((image, index) => {
                if (index < 4) {
                  return (
                    <Image
                      border={
                        bigImage === image.src ? "1px solid black" : "none"
                      }
                      key={index}
                      onClick={() => setBigImage(image.src)}
                      src={image.src}
                      w={50}
                      h={50}
                      mr="8px"
                      background="#F5F5F5"
                    />
                  );
                }
                return null;
              })}
            </Flex>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProductSection;
