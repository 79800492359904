import React from "react";
import { getSingleCityData } from "../../api/getCityData";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import FeedPost from "../city-shop/ui/FeedPost";
import { Box, Flex, Image, Button, Center, Text } from "@chakra-ui/react";

export const NewsFeedSingle = () => {
  const params = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["singleCityPost"],
    queryFn: () => getSingleCityData({ id: params.postId }),
  });

  const post = data?.[0] ?? null;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      fontFamily="Manrope"
      color="black"
      size="12px"
      h="full"
      bg="white"
      maxW={{
        base: "360px",
        sm: "400px",
        lg: "660px",
      }}
      mx="auto"
    >
      <Center>
        <Image
          align="center"
          mt="3rem"
          src="/citylog.png"
          w={{ base: "50%", md: "120px" }}
        />
      </Center>
      <Flex
        justifyContent="space-between"
        mt="16"
        pb="2"
        mx={{ base: "1.5rem", sm: "0" }}
      >
        <Link to="/">
          <Button
            size={{ base: "sm", md: "md" }}
            border="1px solid"
            bg="white"
            borderRadius="none"
          >
            See more
          </Button>
        </Link>
        <Link to="https://recolorred.online">
          <Button
            border="1px solid"
            borderRadius="none"
            size={{ base: "sm", md: "md" }}
            bg="white"
          >
            Go to webstore
          </Button>
        </Link>
      </Flex>
      <Center>
        <FeedPost key={post.sys.id} post={post} />
      </Center>

      <Center mt="4" h="20">
        <Text fontSize="sm" color="black">
          © 2024 Recolorred. All rights reserved.
        </Text>
      </Center>
    </Box>
  );
};
