import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NewsFeed from "./pages/news-feed/NewsFeed";
import CityShopMobile from "./pages/city-shop/CityShopMobile";
import ShopProvider from "./context/shopContext";
import Cart from "./components/Cart/Cart";
import PopupRedirectModal from "./components/PopupRedirect/PopupRedirectModal";
import { NewsFeedSingle } from "./pages/news-feed-single/NewsFeedSingle";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ShopProvider>
          <Router>
            <Routes>
              <Route path="/" element={<NewsFeed />} />
              <Route path="/shop" element={<CityShopMobile />} />
              <Route path="/post/:postId" element={<NewsFeedSingle />} />
            </Routes>

            <Cart />
            <PopupRedirectModal />
          </Router>
        </ShopProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
