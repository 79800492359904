import { CloseIcon } from "@chakra-ui/icons";
import { Grid } from "@chakra-ui/react";

const RoundedCloseButton = ({ onClose }) => {
  return (
    <Grid
      cursor="pointer"
      onClick={onClose}
      placeItems="center"
      border="1px solid black"
      w="35px"
      h="35px"
      borderRadius="full"
    >
      <CloseIcon w={3} h={3} />
    </Grid>
  );
};
export default RoundedCloseButton;
