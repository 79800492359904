import { Box, Flex, Grid, HStack, Image, Text } from "@chakra-ui/react";
import { convertPrice } from "../../utils";
import { CloseIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import { ShopContext } from "../../context/shopContext";

const EachCartItem = ({ item }) => {
  const { removeLineItem, cartLineUpdate } = useContext(ShopContext);
  const [quantity, setQuantity] = useState(item?.quantity ?? 1);

  const increaseQuantity = async () => {
    setQuantity(quantity + 1);
    await cartLineUpdate([{ id: item.id, quantity: quantity + 1 }]);
  };

  const decreaseQuantity = async () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      await cartLineUpdate([{ id: item.id, quantity: quantity - 1 }]);
    }
  };

  return (
    <HStack
      gap={1}
      key={item.id}
      w="100%"
      fontSize="12px"
      borderBottom="1px"
      border="1px"
      borderRight="0px"
      borderLeft="0px"
      _last={{ borderBottom: "1px" }}
      borderTop="0px"
      _first={{ borderTop: "0px" }}
      textTransform="uppercase"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        backgroundColor="#F5F5F5"
        borderRight="1px"
        flex={2}
      >
        <Image src={item.variant.image.src} objectFit="cover" h="full" />
      </Flex>
      <Box flex={5} px="4" pl="0">
        <Flex justifyContent="end">
          <CloseIcon cursor="pointer" onClick={() => removeLineItem(item.id)} />
        </Flex>
        <Text alignItems="center" justifyContent="center">
          {item.title} <br />
          Size: {item.variant.title}
          <br />
          {convertPrice(item.variant.price.amount)}€
        </Text>
        {/* Add Quantity Button */}
        <HStack mt="2" justify="end">
          <Grid
            cursor="pointer"
            onClick={() => decreaseQuantity()}
            boxSize="4"
            placeItems="center"
          >
            -
          </Grid>
          <Grid placeItems="center" boxSize="4">
            <Text>{quantity}</Text>
          </Grid>
          <Grid
            cursor="pointer"
            onClick={() => increaseQuantity()}
            boxSize="4"
            placeItems="center"
          >
            +
          </Grid>
        </HStack>
      </Box>
    </HStack>
  );
};

export default EachCartItem;
