import React, { useContext } from "react";
import {
  Visa,
  Mastercard,
  Paypal,
  Paywithgoogle,
  Applepay,
  Amazon,
} from "react-pay-icons";

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Button,
  Text,
  Flex,
  Box,
  Divider,
  HStack,
  VStack,
  Stack,
} from "@chakra-ui/react";
import EachCartItem from "./EachCartItem";
import ExtraCartPurchase from "./ExtraCartPurchase";
import { ShopContext } from "../../context/shopContext";
import RoundedCloseButton from "./RoundedCloseButton";
const Cart = () => {
  const { isCartOpen, closeCart, checkout, removeLineItem } =
    useContext(ShopContext);

  if (checkout.lineItems === null) {
    checkout.lineItems = [];
  }

  if (!checkout?.lineItems) {
    return null;
  }

  const isCartEmpty = checkout?.lineItems?.length <= 0;

  const renderCart = () => {
    return checkout?.lineItems?.map((item) => (
      <EachCartItem
        item={item}
        key={item.id}
        checkout={checkout}
        removeLineItem={removeLineItem}
      />
    ));
  };

  const renderTotalPrices = () => {
    const total = checkout?.lineItems?.reduce(
      (runningTotal, item) =>
        Number(runningTotal) +
        Number(item.variant.priceV2.amount) * item.quantity,
      0
    );

    const cartItems = checkout?.lineItems?.map((item) => {
      console.log("item", item);

      return {
        name: item.title,
        price: item.variant.priceV2.amount,
        quantity: item.quantity,
      };
    });

    return (
      <>
        <Divider borderColor="black" orientation="horizontal" w="100%" mt="1" />
        <Box px="4" mt="2">
          {cartItems.map((item) => {
            return (
              <HStack justify="space-between" py="2" key={item.name}>
                <Text fontSize="12px" textTransform="uppercase">
                  {item.name}
                </Text>
                <Text fontSize="12px" textTransform="uppercase">
                  {item.price * item.quantity} €
                </Text>
              </HStack>
            );
          })}
          <HStack justify="space-between" py="1.5">
            <Text fontSize="12px" textTransform="uppercase">
              Shipping
            </Text>
            <Text fontSize="12px" textTransform="uppercase">
              0.00 €
            </Text>
          </HStack>
          <HStack justify="space-between" py="1.5">
            <Text fontSize="12px" textTransform="uppercase">
              Total
            </Text>
            <Text fontSize="12px" textTransform="uppercase">
              {total.toFixed(2)} €
            </Text>
          </HStack>
        </Box>
        <Divider borderColor="black" orientation="horizontal" w="100%" mt="1" />
      </>
    );
  };

  const renderEmptyCart = () => {
    return (
      <Box h="100%" w="100%" mt="4">
        <Text
          h="100%"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          Your cart is currently empty
        </Text>
      </Box>
    );
  };

  return (
    <Drawer isOpen={isCartOpen} placement="right" onClose={closeCart} size="sm">
      <DrawerOverlay />
      <DrawerContent backgroundColor="white">
        <Stack justify="space-between" h="100dvh" spacing={0}>
          <Flex justify="space-between" align="center" borderBottom="1px">
            <Text fontSize="12px" textTransform="uppercase" p="1rem">
              Cart
            </Text>
            <Box pt=".5rem" pr="1rem" pb=".5rem">
              <RoundedCloseButton onClose={closeCart} />
            </Box>
          </Flex>
          <Stack spacing={0} flex={1} overflowY="auto">
            <Box fontSize="12px" flex={1} textTransform="uppercase">
              {isCartEmpty ? renderEmptyCart() : renderCart()}
            </Box>
          </Stack>
          <Box>
            {isCartEmpty ? null : (
              <>
                <Box maxH="200px" overflowY="auto">
                  {renderTotalPrices()}
                </Box>
                <ExtraCartPurchase cartItems={checkout?.lineItems ?? []} />
                <Box align="center" mt="20px">
                  <Box align="center" margin="1rem">
                    <Button
                      id="proceed to checkout"
                      _hover={{ opacity: "80%" }}
                      mt="1rem"
                      mb="1rem"
                      height="2.8rem"
                      w="20rem"
                      backgroundColor="black"
                      color="white"
                      borderRadius="0px"
                      fontSize="1rem"
                      boxShadow="none !important"
                      textTransform="uppercase"
                      onClick={() => {
                        window.location.href = checkout.webUrl;
                      }}
                      textDecor="none !important"
                    >
                      PROCEED TO CHECKOUT
                    </Button>
                  </Box>

                  <Divider
                    borderColor="black"
                    orientation="horizontal"
                    w="100%"
                    mb="1"
                  />
                  <VStack>
                    <HStack>
                      <Visa style={{ width: 40 }} />
                      <Mastercard style={{ width: 40 }} />
                      <Paypal style={{ width: 40 }} />
                      <Paywithgoogle style={{ width: 40 }} />
                      <Applepay style={{ width: 40 }} />
                      <Amazon style={{ width: 40 }} />
                    </HStack>
                  </VStack>

                  <Divider
                    borderColor="black"
                    orientation="horizontal"
                    w="100%"
                    mt="1"
                  />
                </Box>
              </>
            )}
          </Box>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};

export default Cart;
