import { Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ShopContext } from "../../context/shopContext";

const CartAccessButton = () => {
  const { openCart, checkout } = useContext(ShopContext);

  if (checkout.lineItems === null) {
    checkout.lineItems = [];
  }
  return (
    <Button
      border="1px solid"
      borderRadius="none"
      size={{ base: "sm", md: "md" }}
      bg="white"
      onClick={() => openCart()}
    >
      Cart ({checkout.lineItems?.length})
    </Button>
  );
};

export default CartAccessButton;
