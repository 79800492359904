import {
  VStack,
  Box,
  Image,
  Center,
  Button,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import FeedPost from "./../city-shop/ui/FeedPost";
import { getCityData, getProrityCityData } from "../../api/getCityData";
import { theme } from "../../theme";
import React from "react";

const LIMIT_PER_PAGE = 20;
const NewsFeed = () => {
  const { data: prority } = useQuery({
    queryKey: ["prorityCityPosts"],
    queryFn: getProrityCityData,
  });

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["cityPosts"],
      queryFn: ({ pageParam }) =>
        getCityData({ page: pageParam, limit: LIMIT_PER_PAGE }),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.length === LIMIT_PER_PAGE
          ? pages.length + 1
          : undefined;
      },
    });

  const [isMobile] = useMediaQuery("(max-width: 30em)");
  const itemsLength = data?.pages?.reduce((acc, page) => acc + page.length, 0);
  console.log("itemsLength", itemsLength);

  return (
    <Box pos="relative" bg="black">
      <Box
        id="layout"
        pos="relative"
        zIndex="5"
        // bgGradient={`linear(to-t, ${theme.colors.black}, ${theme.colors.white})`}
      >
        <Center>
          <Image
            align="center"
            mt="3rem"
            mb="3rem"
            src="citylog.png"
            w={isMobile ? "60%" : "20%"}
          />
        </Center>
        <VStack gap="8">
          {prority?.map((post) => {
            return <FeedPost key={post.sys.id} post={post} />;
          })}

          {data?.pages?.map((posts, i) => (
            <React.Fragment key={i}>
              {posts?.map((post) => {
                return <FeedPost key={post.sys.id} post={post} />;
              })}
            </React.Fragment>
          ))}
          <Button
            mb="4"
            borderRadius="0"
            color="white"
            _hover="none !important"
            background="black"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? "Loading..."
              : hasNextPage
              ? "GO DEEPER"
              : "End"}
          </Button>
          <Box h="10"></Box>

          <Center pos="absolute" w="full" bottom="0" py="4" color="white">
            © 2024 Recolorred. All rights reserved.
          </Center>
        </VStack>
        <Center>
          {isFetching && !isFetchingNextPage ? <Spinner /> : null}
        </Center>
      </Box>
      <Box
        id="layout"
        pos="absolute"
        w="full"
        top="0"
        height={{ base: 500 * 20, lg: 900 * 20 }}
        bgGradient={`linear(to-t, ${theme.colors.black}, ${theme.colors.white})`}
      ></Box>
    </Box>
  );
};
export default NewsFeed;
