import React, { useCallback, useContext, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Center,
  Image,
  Stack,
  useMediaQuery,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ShopContext } from "../../context/shopContext";
import ProductSection from "./ProductSection";
import CartAccessButton from "./CartAccessButton";
import { cityshopBackgroundData } from "../../api/getCityData";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
const ShopMobile = () => {
  const { data } = useQuery({
    queryKey: ["cityshopBackground"],
    queryFn: cityshopBackgroundData,
  });

  const { isLoading, fetchProductsByCollection } = useContext(ShopContext);
  const [products, setProducts] = React.useState([]);
  const category = "cityshop";

  useEffect(() => {
    fetchProductsByCollection(category);
  }, [fetchProductsByCollection, category]);

  const fetchProducts = useCallback(async () => {
    const productFetched = await fetchProductsByCollection(category);
    setProducts(productFetched);
  }, [fetchProductsByCollection, category]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const [isMobile] = useMediaQuery("(max-width: 30em)");

  if (isLoading) {
    return <Center h="100dvh"></Center>;
  }

  const image = data && data[0]?.fields?.backgroundImg?.fields?.file?.url;

  return (
    <>
      <Box
        pb="20"
        pt="8"
        bgImage={`url(${image})`}
        bgSize="cover"
        bgPosition="top"
        minH="100dvh"
        maxW="1440px"
        mx="auto"
      >
        <Box align={isMobile ? "" : "center"} overflow="auto" h="100dvh">
          <HStack
            justify="space-between"
            pb="2"
            w={isMobile ? "" : "80%"}
            px="4"
          >
            <Link to="/">
              <Button
                size={{ base: "sm", md: "md" }}
                border="1px solid"
                bg="white"
                borderRadius="none"
              >
                Go back to city
              </Button>
            </Link>{" "}
            <CartAccessButton />
          </HStack>

          <Accordion
            w={isMobile ? "" : "80%"}
            pos="relative"
            allowToggle
            zIndex="10"
          >
            <Stack px="4" spacing={5}>
              {products?.map((product) => (
                <AccordionItem key={product.id} bg="white">
                  <h2>
                    <AccordionButton py="0">
                      <Box as="span" flex="1" textAlign="left">
                        {product?.title}
                      </Box>
                      <Box borderLeft="1px solid " borderRight="1px solid ">
                        <Image height="90px" src={product?.images[0].src} />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel px="0" pb={4}>
                    {product && <ProductSection product={product} />}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Stack>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default ShopMobile;
