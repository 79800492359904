import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";

import { PlusSquareIcon } from "@chakra-ui/icons";
import { ShopContext } from "../../context/shopContext";
import { convertPrice } from "../../utils";
const ExtraCartPurchase = ({ cartItems }) => {
  const { cartProducts, fetchCartProducts, addExtraItemToCart } =
    useContext(ShopContext);

  useEffect(() => {
    fetchCartProducts();
  }, [fetchCartProducts]);

  const renderProducts = () => {
    if (!cartProducts) return null;
    if (cartProducts.length === 0) return null;

    return cartProducts?.map((item) => {
      const found = cartItems.find(
        (cartItem) => cartItem.variant.product.handle === item.handle
      );

      if (found) return null;

      return (
        <Stack
          gap={1}
          w="100%"
          fontSize="12px"
          borderBottom="1px"
          border="1px"
          borderRight="0px"
          borderLeft="0px"
          _last={{ borderBottom: "1px" }}
          borderTop="0px"
          _first={{ borderTop: "0px" }}
          textTransform="uppercase"
        >
          <Flex>
            <Flex
              flex={2}
              alignItems="center"
              justifyContent="center"
              backgroundColor="#F5F5F5"
              w="11rem"
            >
              <Image src={item.variants[0].image.src} />
            </Flex>
            <Stack flex={5}>
              <Box p="4">
                <Text fontWeight="bold">{item.title}</Text>
                <Text fontSize="xs" noOfLines={2}>
                  {item.description}
                </Text>
                <Text alignItems="center" justifyContent="center">
                  {convertPrice(item.variants[0].price.amount)}€
                </Text>
              </Box>
              <HStack justify="end" p="2">
                <Button
                  backgroundColor="black"
                  color="white"
                  leftIcon={<PlusSquareIcon />}
                  borderRadius="0px"
                  fontSize="14px"
                  boxShadow="none !important"
                  textTransform="uppercase"
                  onClick={() => addExtraItemToCart(item?.variants[0].id, 1)}
                >
                  add
                </Button>
              </HStack>
            </Stack>
          </Flex>
        </Stack>
      );
    });
  };

  return <Stack>{renderProducts()}</Stack>;
};

export default ExtraCartPurchase;
